const rwText = {
  search: "Shakisha",
  searchLabel: "Urifuza kumenya iki?",
  lSelect: "Hitamo Ururimi",
  login: "Injira",
  ulinks: "Ingingo zikunzwe/zamamaye",
  termscontitios: "Amabwiriza y'imikoreshereze",
  privpolicy: "Amabwiriza ajyanye no kurinda amakuru yawe bwite.",
  learnmore: "Menya Ibirenzeho",
  getInvolved: "UKO WATANGA UMUSANZU",
  involvedText:
    "Tugezeho inkuru yawe, udusangize ibyo waciyemo mu kubaka amahoro, uraba utanze umusanzu mu gusobanukirwa  twese hamwe ubumuntu binyunze mu burezi bugamije amahoro n’indangagaciro.",
  stayInTouch: "GUMANA NATWE",
  stayInTouchText:
    "Iyandikishe kugirango wakire ibaruwa mbarankuru yacu, kugira ngo umenye amakuru yose agezweho.",
  joinNewsletter: "IYANDISHE KU KWAKIRA IBARUWA MBARANKURU YACU",
  userFeedbackForm: "IFISHI YAKIRA IBITEKEREZO",
  userFeedbackFormText: "Turifuza kumenya uko wabonye uru rubuga.",
  feedBackForm: "IFISHI YAKIRA IBITEKEREZO",
  teacherActivities: "IBIKORWA BY'ABARENGANZIRA",
  standardAddreses: "IMIGAMBI YASHYIZWEHO",
  learnerActivities: "IBIKORWA BY'ABIGA",
  competiences: "UBUSHOBZI",
  crossCuttingIssues: "IBIBAZO BIKORA KU MASHAMI",
  shareThis: "Sangiza ibi",
  downloadThisArticle: "Kuramo iyi nyandiko",
  titleOfTheLesson: "IZINA RY'ISOMO",
  instructionalObjective: "INTANDARO Y'IKIGAMBO",
  planForThisClass: "GATEGANYIRIZO KU ISOMO RY'IKI GIHE",
  materials: "IBIKORESHO",
  references: "Amakuru",
  originalFileName: "Izina ry'inyandiko y'ibanze",
  size: "Ingano",
  mimeType: "Ubwoko bwa MIME",
  author: "Umwanditsi",
  format: "Isura",
  typeOfMedia: "Ubwoko bw'itangazamakuru",
  interviewees: "Ababajijwe",
  interviewer: "Umubaza",
  language: "Ururimi",
  targetAudiences: "abakurikira intego",
  quizz: "Ikizamini",
  quizzes: "Ibizamini",
  description: "Ibisobanuro",
  questions: "Ibibazo",
  selectStartEndTime:
    "Hitamo igihe gitangira n’igihe kirangira kugira ngo ukore igice cya videwo",
  pageNotFoundTitle: "Paji Ntibabonetse",
  pageNotFoundText: "Duhangayikishije, paji ushaka ntiyabashije kuboneka.",
  pageNotFoundText2: "Nyamuneka subira ku rupapuro rw'ibanze",
  peaceClub: "Ishyirahamwe ry’Amahoro",
  popularArticles: "Inyandiko Zikunzwe",
  category: "Ibyiciro",
  catNews: "Amakuru",
  catEvents: "Ibikorwa",
  catPeaceStories: "Inkuru z'Amahoro",
  catOther: "Ibindi",
};

export default rwText;
