import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import { useGLTranslate } from "modules/website/hooks/useGLTranslate";
import React, { useContext } from "react";

export default function NodeCategoriesDisplay() {
  const { handleSearch, searchArgs, setSearchArgs } = useContext(SearchContext);
  const [Translate] = useGLTranslate();

  const handleChangeCategory = async (e) => {
    const name = e.target.id;
    // checked box
    if (e.target.checked) {
      await setSearchArgs({ ...searchArgs, contenttype: name, page: 1 });
    } else {
      await setSearchArgs({ ...searchArgs, contenttype: "", page: 1 });
    }
    handleSearch(null, true);
    // not checked
  };

  return (
    <div>
      <p className="text-uppercas">{Translate("category")}</p>
      <div className="form-check check-warning">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          id="news"
          onChange={handleChangeCategory}
          checked={searchArgs?.contenttype === "news" ? true : false}
        />
        <label className="text-uppercase cursor-pointer" htmlFor="news">
        {Translate("catNews")}
        </label>
      </div>
      <div className="form-check check-danger">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          onChange={handleChangeCategory}
          id="events"
          checked={searchArgs?.contenttype === "events" ? true : false}
        />
        <label className="text-uppercase cursor-pointer" htmlFor="events">
        {Translate("catEvents")}
        
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          onChange={handleChangeCategory}
          id="peace_stories"
          checked={searchArgs?.contenttype === "peace_stories" ? true : false}
        />
        <label
          className="text-uppercase cursor-pointer"
          htmlFor="peace_stories"
        >
          {Translate("catPeaceStories")}
        </label>
      </div>
      <div className="form-check check-black">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          onChange={handleChangeCategory}
          id="*"
          checked={searchArgs?.contenttype === "*" ? true : false}
        />
        <label className="text-uppercase cursor-pointer" htmlFor="*">
        {Translate("catOther")}
        </label>
      </div>
    </div>
  );
}
